import * as React from 'react';
import * as echarts from 'echarts';
import { useRef, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import { useColorMode } from 'theme-ui';
import { addCommas } from '../utils/numbers';
import Tables from './Tables';
import { StyledPriceNegativeNumber, StyledPricePositiveNumber } from './TrendsTable';

const StyledChart = styled.div`
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  height: 450px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 20px;
  border: 1px solid ${p => p.theme.colors.horizontalRule};
  background: ${p => p.theme.colors.card};
  transition: ${p => p.theme.colorModeTransition};
`;

const StyledRow = styled.tr`
  &:nth-of-type(even) td {
    background: ${p => p.theme.colors.cardAlt} !important;
  }
`;

const StyledCell = styled(Tables.Cell)`
  font-size: 14px;
`;

const StyledHeadCell = styled(Tables.HeadCell)`
  text-align: right;
`;

const StyledCellRight = styled(StyledCell)`
  text-align: right;
`;

export function Chart({ data }) {
  const [] = React.useState(false);
  const [colorMode] = useColorMode();
  const isDark = colorMode === `dark`;

  const divRef = useRef<HTMLDivElement | null>(null);
  const chart = useRef<echarts.ECharts | null>(null);

  const fixedData = data[0].whenLong ? [...data].reverse() : data;

  const when = fixedData.map(i => i.when);
  const closes = fixedData.map(i => (i.close < 0.01 ? i.close.toFixed(4) : i.close.toFixed(2)));
  const relVol = fixedData.map(i => (!i.avgVolume3m ? '0.00' : (i.volume / i.avgVolume3m).toFixed(2)));
  const volume = fixedData.map(i => i.volume);
  const mentions = fixedData.map(i => i.mentions?.total ?? 0);

  const fractionalPennies = closes.some(i => i > 0 && i < 0.01);
  const yAxisDecimals = fractionalPennies ? 4 : closes.every(i => i < 10) ? 2 : 0;
  const extraLeftPadding = yAxisDecimals === 4 ? 10 : 0;

  useLayoutEffect(() => {
    if (!divRef.current) {
      console.error('no element to render chart into');
      return;
    }

    const chartTheme = isDark ? 'dark' : '';
    chart.current = echarts.init(divRef.current, chartTheme);

    const decalColor = 'rgba(0, 0, 0, 0.085)';
    const option: echarts.EChartsOption = {
      backgroundColor: 'transparent',
      darkMode: isDark,
      tooltip: {
        formatter: d => {
          return `<div>
            <div style="font-size:14px;color:#666;font-weight:900;line-height:1;">${when[d.dataIndex]}</div>
            <div style="margin:10px 0 0;line-height:1;">
              <span style="display:inline-block; margin-right:4px; border-radius: 10px; width: 10px; height: 10px; background-color: #5470c6;"></span>
              <span style="font-size:14px; color: #666; font-weight: 400; margin-left: 4px">Close</span>
              <span style="float: right; margin-left: 20px; font-size:14px; color: #666; font-weight: 900;">$ ${fixedData[
                d.dataIndex
              ].close.toFixed(2)}</span>
              <div style="clear:both;"></div>
            </div>
            <div style="margin:10px 0 0;line-height:1;">
              <span style="display:inline-block; margin-right:4px; border-radius: 10px; width: 10px; height: 10px; background-color: #5470c6;"></span>
              <span style="font-size:14px; color: #666; font-weight: 400; margin-left: 4px">Open</span>
              <span style="float: right; margin-left: 20px; font-size:14px; color: #666; font-weight: 900;">$ ${fixedData[
                d.dataIndex
              ].open.toFixed(2)}</span>
              <div style="clear:both;"></div>
            </div>
            <div style="margin:10px 0 0;line-height:1;">
              <span style="display:inline-block; margin-right:4px; border-radius: 10px; width: 10px; height: 10px; background-color: #5470c6;"></span>
              <span style="font-size:14px; color: #666; font-weight: 400; margin-left: 4px">Low</span>
              <span style="float: right; margin-left: 20px; font-size:14px; color: #666; font-weight: 900;">$ ${fixedData[
                d.dataIndex
              ].low.toFixed(2)}</span>
              <div style="clear:both;"></div>
            </div>
            <div style="margin:10px 0 0;line-height:1;">
              <span style="display:inline-block; margin-right:4px; border-radius: 10px; width: 10px; height: 10px; background-color: #5470c6;"></span>
              <span style="font-size:14px; color: #666; font-weight: 400; margin-left: 4px">High</span>
              <span style="float: right; margin-left: 20px; font-size:14px; color: #666; font-weight: 900;">$ ${fixedData[
                d.dataIndex
              ].high.toFixed(2)}</span>
              <div style="clear:both;"></div>
            </div>
            <div style="margin:10px 0 0;line-height:1;">
              <span style="display:inline-block; margin-right:4px; border-radius: 10px; width: 10px; height: 10px; background-color: #91cc75;"></span>
              <span style="font-size:14px; color: #666; font-weight: 400; margin-left: 4px">Volume</span>
              <span style="float: right; margin-left: 20px; font-size:14px; color: #666; font-weight: 900;">${addCommas(
                fixedData[d.dataIndex].volume
              )}</span>
              <div style="clear:both;"></div>
            </div>
            <div style="margin:10px 0 0;line-height:1;">
              <span style="display:inline-block; margin-right:4px; border-radius: 10px; width: 10px; height: 10px; background-color: rgb(250, 200, 88);"></span>
              <span style="font-size:14px; color: #666; font-weight: 400; margin-left: 4px">Rel. Volume</span>
              <span style="float: right; margin-left: 20px; font-size:14px; color: #666; font-weight: 900;">${relVol[d.dataIndex]}</span>
              <div style="clear:both;"></div>
            </div>
            <div style="margin:10px 0 0;line-height:1;">
              <span style="display:inline-block; margin-right:4px; border-radius: 10px; width: 10px; height: 10px; background-color: #ee6666;"></span>
              <span style="font-size:14px; color: #666; font-weight: 400; margin-left: 4px">Mentions</span>
              <span style="float: right; margin-left: 20px; font-size:14px; color: #666; font-weight: 900;">${addCommas(
                mentions[d.dataIndex]
              )}</span>
              <div style="clear:both;"></div>
            </div>
            <div style="clear:both;"></div>
          </div>`;
        }
      },
      aria: {
        decal: {
          show: true,
          decals: [
            {
              color: decalColor,
              dashArrayX: [1, 0],
              dashArrayY: [2, 5],
              symbolSize: 1,
              rotation: Math.PI / 6
            }
          ]
        }
      },
      xAxis: [
        {
          type: 'category',
          data: when,
          boundaryGap: true,
          axisLine: { lineStyle: { color: '#777' } },
          axisLabel: {
            // formatter: (value) => {
            //   return echarts.format.formatTime('MM-dd', value);
            // }
          },
          min: 'dataMin',
          max: 'dataMax'
          // axisPointer: {
          //   show: true
          // }
        },
        {
          type: 'category',
          gridIndex: 1,
          data: when,
          // scale: true,
          boundaryGap: true,
          splitLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          axisLine: { lineStyle: { color: '#777' } },
          min: 'dataMin',
          max: 'dataMax'
        },
        {
          type: 'category',
          gridIndex: 2,
          data: when,
          boundaryGap: true,
          splitLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          axisLine: { lineStyle: { color: '#777' } },
          min: 'dataMin',
          max: 'dataMax'
        },
        {
          type: 'category',
          gridIndex: 3,
          data: when,
          boundaryGap: true,
          splitLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          axisLine: { show: false },
          min: 'dataMin',
          max: 'dataMax'
        }
      ],
      // yAxis: {
      //   type: 'value'
      // },
      yAxis: [
        {
          scale: true,
          splitNumber: 2,
          axisLine: { lineStyle: { color: '#777' } },
          splitLine: { show: true },
          axisTick: { show: false },
          axisLabel: {
            inside: false,
            formatter: d => `$ ${d.toFixed(yAxisDecimals)}`
          }
        },
        {
          scale: true,
          gridIndex: 1,
          splitNumber: 2,
          axisLabel: { show: false },
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: { show: false }
        },
        {
          scale: true,
          gridIndex: 2,
          splitNumber: 2,
          axisLabel: { show: false },
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: { show: false }
        },
        {
          type: 'log',
          scale: true,
          gridIndex: 3,
          axisLabel: { show: false },
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: { show: false }
        }
      ],
      axisPointer: {
        link: [
          {
            xAxisIndex: [0, 1]
          }
        ]
      },
      grid: [
        {
          left: 55 + extraLeftPadding,
          right: 15,
          top: 25,
          height: 290
        },
        {
          left: 55 + extraLeftPadding,
          right: 15,
          bottom: 15,
          top: 360
        },
        {
          left: 55 + extraLeftPadding,
          right: 15,
          bottom: 15,
          top: 350
        },
        {
          left: 55 + extraLeftPadding,
          right: 15,
          top: 25,
          height: 145
        }
      ],
      series: [
        {
          name: 'Closing Price',
          data: closes,
          type: 'line',
          areaStyle: {}
          // label: {
          //   formatter: '$ {value}\n'
          // }
          // tooltip: {
          //   formatter: value => `$ ${value.data}`
          // }
        },
        // {
        //   data: sticks,
        //   type: 'candlestick'
        //   // areaStyle: {}
        // },
        {
          name: 'Volume',
          type: 'bar',
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: volume
        },
        {
          name: 'Rel. Vol',
          data: relVol,
          type: 'line',
          xAxisIndex: 2,
          yAxisIndex: 2
        },
        {
          name: 'Mentions',
          data: mentions,
          type: 'line',
          xAxisIndex: 3,
          yAxisIndex: 3,
          smooth: false
        }
      ]
    };
    chart.current.setOption(option);
  }, []);

  function toLongDate(when: string, whenLong: string | undefined) {
    if (!whenLong) return when;
    return `${when}, ${whenLong.substr(0, 4)}`;
  }

  function RelativeVolume({ value }) {
    return value > 1 ? (
      <StyledPricePositiveNumber>{value.toFixed(2)}</StyledPricePositiveNumber>
    ) : value < 1 ? (
      <StyledPriceNegativeNumber>{value.toFixed(2)}</StyledPriceNegativeNumber>
    ) : (
      <>{value.toFixed(2)}</>
    );
  }

  function valueToFixed(value: number): string {
    if (fractionalPennies) return value.toFixed(4);
    return value.toFixed(2);
  }

  function PriceChange({ value = 0 }) {
    if (value > 0) {
      return <StyledPricePositiveNumber>+{valueToFixed(value)}</StyledPricePositiveNumber>;
    }
    if (value < 0) {
      return <StyledPriceNegativeNumber>{valueToFixed(value)}</StyledPriceNegativeNumber>;
    }
    return null;
  }

  return (
    <>
      <StyledChart style={{ marginBottom: '-20px' }} ref={divRef}>
        {' '}
      </StyledChart>
      <Tables.Table>
        <Tables.Head>
          <tr>
            <Tables.HeadCell>Date</Tables.HeadCell>
            <StyledHeadCell>Open</StyledHeadCell>
            <StyledHeadCell>High</StyledHeadCell>
            <StyledHeadCell>Low</StyledHeadCell>
            <StyledHeadCell>Close</StyledHeadCell>
            <StyledHeadCell title="The change in closing price">Change</StyledHeadCell>
            <StyledHeadCell title="The volume traded on the day">Volume</StyledHeadCell>
            <StyledHeadCell title="Relative volume over a period of 20-days">R. Vol. (20d)</StyledHeadCell>
            <StyledHeadCell title="Relative volume over a period of 3-months">R. Vol. (3m)</StyledHeadCell>
            <StyledHeadCell title="Mentions">Mentions</StyledHeadCell>
          </tr>
        </Tables.Head>
        <Tables.Body>
          {data
            .filter((_, index, arr) => index < arr.length - 1)
            .map((i, index) => (
              <StyledRow key={i.when}>
                <StyledCell>{toLongDate(i.when, i.whenLong)}</StyledCell>
                <StyledCellRight title={i.open}>$ {valueToFixed(i.open)}</StyledCellRight>
                <StyledCellRight title={i.high}>$ {valueToFixed(i.high)}</StyledCellRight>
                <StyledCellRight title={i.low}>$ {valueToFixed(i.low)}</StyledCellRight>
                <StyledCellRight title={i.close}>$ {valueToFixed(i.close)}</StyledCellRight>
                <StyledCellRight>
                  <PriceChange value={i.close - data[index + 1].close} />
                </StyledCellRight>
                <StyledCellRight title={addCommas(i.volume)}>{addCommas(i.volume)}</StyledCellRight>
                <StyledCellRight title={i.avgVolume20d ? `Avg. Volume 20-days: ${i.avgVolume20d?.toFixed(0)}` : 'Not Available'}>
                  {i.avgVolume20d ? <RelativeVolume value={i.volume / i.avgVolume20d} /> : 'N/A'}
                </StyledCellRight>
                <StyledCellRight title={i.avgVolume3m ? `Avg. Volume 3 months: ${i.avgVolume3m?.toFixed(0)}` : 'Not Available'}>
                  {i.avgVolume3m ? <RelativeVolume value={i.volume / i.avgVolume3m} /> : 'N/A'}
                </StyledCellRight>
                <StyledCellRight>{i.mentions?.total && <>{addCommas(i.mentions.total)}</>}</StyledCellRight>
              </StyledRow>
            ))}
          {/* {!showAllRawData && (
            <tr>
              <td colSpan={8}>Show rest</td>
            </tr>
          )} */}
        </Tables.Body>
      </Tables.Table>
    </>
  );
}
