import * as React from 'react';
import * as echarts from 'echarts';
import { useRef, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import { colors } from '../styles/variables';
import { addCommas } from '../utils/numbers';
import { useColorMode } from 'theme-ui';

const StyledChart = styled.div`
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  height: 420px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 20px;
  border: 1px solid ${p => p.theme.colors.horizontalRule};
  background: ${p => p.theme.colors.card};
  transition: ${p => p.theme.colorModeTransition};
`;

export function FailuresChart({ data }) {
  const [colorMode] = useColorMode();
  const isDark = colorMode === `dark`;

  const divRef = useRef<HTMLDivElement | null>(null);
  const chart = useRef<echarts.ECharts | null>(null);

  const when = data.map(i => i.lastUpdatedEst);
  const total = data.map(i => i.total);

  useLayoutEffect(() => {
    if (!divRef.current) {
      console.error('no element to render chart into');
      return;
    }

    const chartTheme = isDark ? 'dark' : '';
    chart.current = echarts.init(divRef.current, chartTheme);

    const decalColor = 'rgba(0, 0, 0, 0.085)';
    const option: echarts.EChartsOption = {
      backgroundColor: 'transparent',
      darkMode: isDark,
      tooltip: {},
      aria: {
        decal: {
          show: true,
          decals: [
            {
              color: decalColor,
              dashArrayX: [1, 0],
              dashArrayY: [2, 5],
              symbolSize: 1,
              rotation: Math.PI / 6
            }
          ]
        }
      },
      xAxis: [
        {
          type: 'category',
          data: when,
          boundaryGap: false,
          axisLine: { lineStyle: { color: '#777' } },
          axisLabel: {},
          min: 'dataMin',
          max: 'dataMax'
        }
      ],
      yAxis: [
        {
          scale: true,
          splitNumber: 2,
          axisLine: { lineStyle: { color: '#777' } },
          splitLine: { show: true },
          axisTick: { show: false },
          axisLabel: {
            inside: false
          }
        }
      ],
      grid: [
        {
          left: 80,
          right: 60,
          top: 25,
          height: 355
        }
      ],
      series: [
        {
          name: 'Aggregate Total',
          data: total,
          type: 'line',
          color: '#FF4500',
          areaStyle: {}
        }
      ]
    };
    chart.current.setOption(option);
  }, []);

  return <StyledChart ref={divRef}> </StyledChart>;
}
